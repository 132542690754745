exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-physician-directory-jsx": () => import("./../../../src/pages/physician-directory.jsx" /* webpackChunkName: "component---src-pages-physician-directory-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-templates-media-template-jsx": () => import("./../../../src/templates/media-template.jsx" /* webpackChunkName: "component---src-templates-media-template-jsx" */),
  "component---src-templates-news-post-template-jsx": () => import("./../../../src/templates/news-post-template.jsx" /* webpackChunkName: "component---src-templates-news-post-template-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/news-template.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */),
  "component---src-templates-office-template-jsx": () => import("./../../../src/templates/office-template.jsx" /* webpackChunkName: "component---src-templates-office-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-reserve-template-jsx": () => import("./../../../src/templates/reserve-template.jsx" /* webpackChunkName: "component---src-templates-reserve-template-jsx" */),
  "component---src-templates-service-template-jsx": () => import("./../../../src/templates/service-template.jsx" /* webpackChunkName: "component---src-templates-service-template-jsx" */),
  "component---src-templates-topic-template-jsx": () => import("./../../../src/templates/topic-template.jsx" /* webpackChunkName: "component---src-templates-topic-template-jsx" */)
}

